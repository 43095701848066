.score {
	display: flex;
    flex-direction: column;
    justify-content: center;
	width: 6rem;
	height: 6rem;
	margin-top:0.25rem;
	margin-bottom:0.25rem;
	position: relative;
	p {
		text-align: center;
		font-weight: bold;
	}
	.trend-arrow__up {
		position: absolute;
		left: 0.3rem;
		bottom: 0.3rem;
	}
	  
	.trend-arrow__down {
		transform: rotate(180deg);
		left: 0.3rem;
		bottom: 0.3rem;
	}

	.trend-arrow__none {
		display: none;
	}
}

.info-panel-practice-cell {
	.trend-arrow__up {
		width: 1.7rem;
		position: absolute;
		left: 0.3rem;
		bottom: 0.3rem;
	}
	  
	.trend-arrow__down {
		transform: rotate(180deg);
		width: 1.7rem;
		left: 0.3rem;
		bottom: 0.3rem;
	}
	
	.trend-arrow__none {
		display: none;
	}

}
