.practice-snapshots {
	background: white;
	margin-top: 5rem;
	padding-top: 2.5rem;
	padding-bottom: 5rem;
	
	p {
		color: black;
	}

	table {
		margin-left: auto;
		margin-right: auto;
	}

	tr {
		padding: 5rem;
	}
}