@import 'vars';

.practice-snapshot {
	display: inline-flex;
	margin-top:2.5rem;
	margin-bottom: 2.5rem;
	width: 100%;
	padding-left: 2.5rem;
	padding-right:2.5rem;

	.practice-snapshot__score {
		border-radius: 0.5rem;
		width: 18%;
		height: 9rem;
		margin-bottom: auto;
		margin-top: auto;
		padding-top: 1.5rem;
	}

	.practice-snapshot__description {
		border-right: 0.1rem solid #00000030;
		padding-left: 3rem;
		padding-right: 3rem;
		text-align: left;
		width: 30%;
		color: black;
	}

	.practice-snapshot__insights {
		padding-left: 3rem;
		padding-right: 3rem;
		text-align: left;
		width: 30%;
	}

	.practice-snapshot__action-button {

		a {
			color: #4a90e2;
			text-decoration: none;
		}

		a:hover {
			color: #fff;
			text-decoration: none;
		}

		border: 0.2rem solid #dfdfdf;
	    border-radius: 3rem;
	    width: 110%;
	    margin-left: 3rem;
	    margin-top: auto;
	    margin-bottom: auto;
	    padding: 1rem;
	}

	a:hover {
			text-decoration: none;
			border: none;
		}

	.practice-snapshot__action-button:hover {
			background-color: #4A90E2;
			color: #fff;
			text-decoration: none;
			border: 0.2rem solid #4A90E2;
		}


	.fake-link {
		color: blue;
	}
}