.heatmap-team {
	color: white;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
}

.heatmap-team__not-selected {
	opacity: 0.25;
}

.heatmap-team-column {
	display: inline-block;
	p {
		// color: white;
		height: 20rem;
		text-align: left;
		font-weight: bold;
		-webkit-mask-image: -webkit-linear-gradient(top, white, #000000c7, #00000000);
		writing-mode: vertical-rl;
		transform: rotate(180deg);
		white-space: nowrap;
		padding-top: 2rem;
		// used to center
		padding-right: 1rem;
	}
}

.heatmap-team__selected {
	p {
		font-size:1.6rem;
		-webkit-mask-image: -webkit-linear-gradient(top, white, #000000FF, #00000000);
	}
	.heatmap-team__cells-container {
		box-shadow: 0px 0px 20px 0px #00000088;
	}
}


