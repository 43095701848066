@import 'vars';
.heatmap {
	justify-content: center;
	padding-bottom: 5rem;
	table {
		left: 50%;
		position: relative;
		transform: translateX(-50%);
		background: white;
	}

	td {
		border: 1px solid $grey;
		padding: 0px;
	}

	.heatmap__date{
		color: #FFF;
	}

	.heatmap__dateSlider {
		width: 65%;
		margin: auto;
		padding-top:20px;
	}
}