.button-group {
  margin: 1em 0;
  list-style-type: none;
  padding: 0;
}

.button-group li {
  display: block;
  float: left;
  margin: 0 1em 0 0;
  position: relative;
}

.button-group input {
  display: block;
  position: absolute;
}

.button-group label {
  border-radius: 32px;
  padding: 0.5em 3em;
  cursor: pointer;
  z-index: 90;
  font-size: 0.9em;
}

// The self-blocking "button group" is just its own button.
.button-group.self-blocking {
  padding: 0;
  margin: 0;
  flex: 0 1 25%;
}

.button-group label.self-blocking {
  border-radius: 4px;
  padding: 0.5em 2em;
  font-size: 0.8em;
}

.button-group input[type="radio"] {
  opacity: 0;
  z-index: 100;
}

.yes-no-description {
  font-size: 13px;
}

/* ---=== Question Line Section ===--- */

.data-question {
  display: flex;
  align-items: baseline;
}

.data-question p {
  flex: 0 1 auto;
}

.flex-push {
  margin-left: auto;
}

/* ---===       Colours       ===--- */

.button-group label.positive:hover { background: #9d98; }
.button-group label.neutral:hover { background: #cbe8; }
.button-group label.purple:hover { background: #cbe8; }

.button-group label.negative:hover,
.button-group label.self-blocking:hover { background: #fbb8; }

.button-group input[type="radio"].positive:checked + label {
  background: #9d9;
  color: #171;
}

.button-group input[type="radio"].negative:checked + label,
.button-group input[type="radio"].self-blocking:checked + label {
  background: #fbb;
  color: #f33;
}

.button-group input[type="radio"].neutral:checked + label {
  background: #cbe;
  color: #61e;
}

.button-group input[type="radio"] + label {
  background: #ddd;
  color: #666;
}