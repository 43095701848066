.info-panel-practice-cell {
	display: flex;
	padding-left:1.2rem;

	.info-panel-practice-cell__description {
		margin: 0rem 2rem;
		display: flex;
		align-items: center;
		span { 
  			letter-spacing: -0.22px;
			width: 40rem;
			font-size: 15px;
			color: #181818;
			display: inline-block;
			vertical-align: middle;
			line-height: normal;
		}

		.info-panel-practice-cell__description__trend {
			color: #b0b0b0;
			font-size: 1.1rem;
		}
	}
	.info-panel__view-button {
		display: flex;
		text-decoration: none;
	}

	&:hover {
		background-color: #2c99ed1f;
		.info-panel__view-button{
			color: #2c99ed;
		}
	}

}