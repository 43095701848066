.heatmap-info-panel {
	position: absolute;
	// left: 50vw - width;
	z-index: 50;
	justify-content: center;
	display: inline-block;
	margin-bottom: 25px;
	transition: opacity 333ms;

	.heatmap-info-panel_header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}


	.update_team {
	border: 0.2rem solid #ffffff82;
    border-radius: 3rem;
    width: 120%;
    margin-top: auto;
    margin-bottom: auto;
    padding: 0.5rem 1rem 0.5rem 1rem;
    font-size: 14px;
    color: #fff;
	}

	.update_team:hover {
		background-color: #4A90E2;
	}

	.heatmap-info-panel__box {
		display: flex;
		box-shadow: 0 8px 16px rgba(0, 0, 0, 0.333);
		background: #FFFFFF;
		text-align: left;
		border-radius: 1rem;
		// position: absolute;
		// margin: 1.5rem;
		padding-bottom: 2.4rem;
		padding-top: 1.1rem;
	}

	.heatmap-info-panel__team-name-header {
		color: white;
		text-align: left;
		margin-left: 2rem;
		padding-top: 20px;
	}

	.heatmap-info-panel-close {
		text-align: right;
		padding-bottom: 5px;
		font-size: 25px;
		color: #fff;
	}

	.heatmap-info-panel-actions {
		margin-right: 25px;
	}

	.heatmap-info-panel-button {
		margin-right: 20px;
	}
	
	.heatmap-info-panel-button a:hover{
		text-decoration: none;
	}

	.heatmap-info-panel__team-name{
		color: white;
		text-align: left;
		font-size: 2rem;
		margin-top: 0rem;
		margin-bottom: 2rem;
		margin-left: 2rem;
		opacity: 1;
	}

	.heatmap-info-panel__view-button {
		padding-right: 2.4rem;
	}

	.heatmap-info-panel__view-button a:hover {
		text-decoration: none;
	}
}

.is-hidden {	
	opacity: 0;
}

.black-layer {
	background: black;
	height: 100vh;
	left:0px;
	top:0px;
	width: 100vw;
	z-index: -100;
	position:fixed;
	opacity:0.5;
	-webkit-mask-image: linear-gradient(white, white, #00000088);
}

.black-layer.is-hidden {
	display: none;
}