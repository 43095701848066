@import 'vars';

.heatmap-cell__container {
  // background-color: white;
  height: 4rem;
}

.heatmap-cell {
    float: left;
    font-size: 24px;
    font-weight: bold;
    line-height: 3rem;
    text-align: center;
    height: 4rem;
    width: 4rem;
    color: white;
    margin-bottom: 0.1rem;

    p{
      font-size: 10px;
    }

    // &.isSemiOpen-sameTeam {
    //     z-index: 5;
    //     position: relative;
    //     box-shadow: 0.1rem 0 0.25rem rgba(0, 0, 0, 0.666), -0.1rem 0 0.25rem rgba(0, 0, 0, 0.666);
    // }

    // &.isSemiOpen-samePractice {
    //     z-index: 5;
    //     position: relative;
    //     box-shadow: 0 0.75rem 0.75rem rgba(0, 0, 0, 0.666);
    // }

    &.isSemiOpen {
        z-index: 5;
        position: relative;
    }

    &.isOpen {
        // transform: scale(1.5);
        z-index: 10;
        position:relative;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.666);
        border-radius: 0.3rem;
    }

    &.notSemiOpen {
        // filter: blur(2px);
        opacity: 0.5;

    }

    &.openInfoModal {
        transform: scale(2);
    }

    // &:hover {
    //  background:#f00;
    // }

    .isNotVisible {
        display: none;
    }

    .trend-arrow {
      transform: scale(0.75);
      position: relative;
      right: 1rem;
      bottom: -1rem;
    }

    .trend-arrow__down{
      transform: rotate(180deg) scale(0.75);
    }

    .trend-arrow__none{
      display: none;
    }
}

