.three-five-three-cell {
	width: 4rem;
	height: 4rem;
	margin: 0.05rem;
	p {
		font-size: 1.5rem;
		padding-top: 0.75rem;
		text-align: center;
	}
	
	.isNotVisible {
        display: none;
    }

	// For the trending arrows on the cells
    .trend-arrow {
      transform: scale(0.75);
      position: relative;
      right: 1rem;
      bottom: -1rem;
    }

    .trend-arrow__down{
      transform: rotate(180deg) scale(0.75);
    }

    .trend-arrow__none{
      display: none;
    }
}