*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }
html {
  font-size: 62.5% !important; 
}
body {
  font-family: 'Open Sans' !important;
  font-size: 1.6rem;
  line-height: 1.7;
  color: #777;
  box-sizing: border-box;
  overflow-y: auto !important; 
  overflow-y: auto !important; 
  background-color: #0072ca;
}
p {
  font-size: 1.4rem;
  margin-bottom: 0px;
}
html,body {
  height: 100%; margin: 0px; padding: 0px; 
}

.App {
  min-height: 100vh;
  overflow: hidden;
  display: block;
  position: relative;
  padding-bottom: 60rem;
}

.App > main > div {
  display: block;
    margin-left: auto;
    margin-right: auto;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

