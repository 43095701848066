.team-health {
	text-align: center;
	height: 100vh;
	padding: 16rem;
	h1 {
		color: #FFFFFF;
		font-size: 7rem;
	}

	h2 {
		margin-top: 2.5rem;
		margin-bottom: 7rem;
		opacity: 0.6;
		color: #FFFFFF;
	}

	.search-bar {
		input[type=text] {
			margin-bottom: 7rem;
			padding-left: 2rem;
			padding-right:2rem;
		    background-color: #008cd494;
		    background-image: url('../Assets/Img/searchicon.png');
		    background-position: 95% 50%;
		    background-repeat: no-repeat;
		    background-size: 3rem 3rem;
		    color: white;
		    width: 33vw;
		    height: 6rem;
		    border-radius: 0.5rem;
		    border: 2px solid #ffffff60;
		}

		input::placeholder{
			color: white;
			font-size: 2rem;
		}
	}
}

.company-name-copy {
	font-size: 24px;
    color: #9dcefb;
    padding-bottom: 5px;
}


.mailto_link {
	color: #fff;
}

.mailto_link:hover {
    color: #fff;
    text-decoration: underline;
}

h1.logged_out_copy {
    font-size: 5rem;
}

.about-team-insights {
	font-size: 2.5rem;
    text-align: left;
    padding-top: 50px;
}

.about-team-insights-copy {
	margin-right: 35%;
    margin-top: 10px;
    font-size: 28px;
    margin-left: 10px;
}

.heatmap_img {
	width: 330px;
    padding-right: 10px;
    margin-top: 60px;
}

.enquiries {
	padding-top: 30px;
}

.mailchimp-subscribe {
	padding-top: 30px;
}

.company-picker {
	display: flex;
	justify-content: center;
	padding-bottom: 20px;
}