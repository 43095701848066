.data-entry-yes-no {
  padding-bottom: 45px;
}

.data-entry-component-yes-no {
  display: flex;
}

.data-entry-component-yes-no button:focus {
  outline: 0;
}

button .selected {
  background-color: #9ce;
}