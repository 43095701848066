.TimeSeries {
	background-color: #f0f0f0;
	height: auto;
	margin-top: 5rem;
	// margin-bottom: 5rem;
	color: #000;

	h2 {
		padding-top: 8rem;
		padding-bottom: 3rem;
		text-transform: uppercase;
	}

	.practice-dropdown {
		margin-bottom: 8rem;
	}
}