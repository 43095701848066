.signup-button {
	font-size: 2em;
	background-color: #0072ca;
	border-color: #fff;
	border-radius: 3rem;
	width: 50%;
	color: #fff;
}

.signup-button:hover {
	background-color: #fff;
	color: #0072ca;

}

.instruction {
		font-size: 16px;
		text-align: left;
		font-style: italic;
	}