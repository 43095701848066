.Footer-container {
  height: 10rem;
  width: 100vw;
  padding-top: 5rem;
  background: white;
  bottom: 0;
  position: absolute;

  .Copyrights-box {
    position: absolute;
    left: 4.5rem;
    // margin: 2.2rem 0;
    .Copyrights-box {
      display: inline-block;
    }
  }
  .Footer-menu__list {
    position: absolute;
    right: 8rem;
    // margin: 2.2rem 0;
  }
  .Footer-menu__item {
    display: inline-block;
    margin: 0 2.9rem;
    padding: 0 1rem;
    font-size: 1.8rem;
    text-transform: uppercase;
  }
  .Footer-menu__box {
    text-align: right;
    padding-right: 30px;
  }
}