@import 'vars';

.team-form {
	background: #fff;
	padding-left: 14rem;
	padding-right: 14rem;
	padding-bottom: 10rem;
	margin-top: 160px;
	margin-bottom: 160px;
	width: 800px;

	h1 {
		color: #FFFFFF;
		font-size: 7rem;
	}

	h2 {
		margin-bottom: 6rem;
		color: #0072CA;
		font-size: 3rem;
	}

	.health-form-title {
		padding-top: 6rem;
		font-size: 22px;
		color: #4b4b4b;
		text-align: center;
	}


	/*Main Body Styling*/

	.team-question {
		text-align: left;
		font-weight: 600;
		font-size: 16px;
	}

	.instruction {
		font-size: 14px;
		text-align: left;
		font-style: italic;
	}

	.annotations-field {
		padding-top: 30px;
		padding-bottom: 60px;
		text-align: left;
	}

	.annotations-field-copy {
		text-align: left;
		padding-bottom: 30px;
		padding-top: 30px;
		font-weight: 600;
		font-size: 18px;
		color: #2f2f2f;
	}

	.DatePicker {
		font-size: 16px;
	}


}
