.TeamView {
	padding-top: 14rem;
	text-align: center;

	color: white;

	h1 {
		font-size: 5rem;
	}

	p {
		display: inline;
	}
	#focus {
		opacity: 0.6;
	}

	.back-button {
    	position: absolute;
    	left: 5%;
    	display: grid;
    	.button {
    		font-weight: bold;
    		font-size: 2.5rem;
    		color: white;
    		text-decoration: none;
    	}

    	.text {
    		opacity: 0.6;
    	}
    }
}

.Practices {
	background: white;
}

.practice-snapshot__highlighted {
	background-color: #2c99ed1f;
}