.heatmap-info-panel-practice-cell {
	display: flex;
	padding-left:2.4rem;
	.score {
		width: 6rem;
		height: 3.6rem;
		margin-top:0.25rem;
		margin-bottom:0.25rem;
		padding-top:0.6rem;
		p {
			text-align: center;
			font-weight: bold;
		}
	}

	.heatmap-info-panel-practice-cell__description {
		margin-left: 2rem;
		margin-right: 2rem;
		p {
			width: 30rem;
			height: 2rem;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			color: #181818;
		}

		.heatmap-info-panel-practice-cell__description__trend {
			color: #b0b0b0;
			font-size: 1.1rem;
		}
	}
	.heatmap-info-panel__view-button {
		display: flex;
		text-decoration: none;
	}

	&:hover {
		background-color: #2c99ed1f;
		.heatmap-info-panel__view-button{
			color: #2c99ed;
		}
	}

	.trend-arrow {
      transform: scale(0.75);
      position: relative;
      right: -0.1rem;
      bottom: 1.8rem;
    }
    
    .trend-arrow__down{
      transform: rotate(180deg) scale(0.75);
    }

    .trend-arrow__none{
      display: none;
    }
}