$grey: #b8b8b8;
$self-blocking: #e84226;
$not-yet-checked: #606060;
$practice-not-evident: #f49c9a;
$inconsistent-practice: #b8e689;
$consistent-practice: #00d146;
$effective-practice: #00a7ff;

.score-colors__not-relevant {
	background: $grey;
}
.score-colors__self-blocking {
	background: $self-blocking;
	p {
		color: black;
	}
}
.score-colors__not-yet-checked {
	background: $not-yet-checked;
	p {
		color: white;
	}
}
.score-colors__practice-not-evident {
	background: $practice-not-evident;
	p {
		color: black;
	}
}
.score-colors__inconsistent-practice {
	background: $inconsistent-practice;
	p {
		color: black;
	}
}
.score-colors__consistent-practice {
	background: $consistent-practice;
	p {
		color: black;
	}
}
.score-colors__effective-practice {
	background: $effective-practice;
	p {
		color: black;
	}
}