p {
	text-align: left;
}

.data-question p {
	font-weight: 600;
	font-size: 16px;
}

.data-entry-slider {
	padding-bottom: 45px;
}

.slider-description {
	font-size: 13px;
}