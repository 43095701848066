@import 'vars';

.health-check-form {
	text-align: center;
	background: #fff;
	padding: 0 10rem 10rem 10rem;
	margin-top: 160px;
	margin-bottom: 160px;
	width: 800px;

	.name-date {
		display:flex;
		/*align-items: center;*/
   		justify-content: center;
	}

	.team-name-style{
		display: flex;
    	align-items: center;
	}

	.on-the {
		padding-left: 12px;
		padding-right: 12px;
	}

	.team-name-header {
		font-size: 2rem;
		color: #0072CA;
	}

	h1 {
		color: #FFFFFF;
		font-size: 7rem;
	}

	h2 {
		margin-bottom: 6rem;
		color: #0072CA;
		font-size: 3rem;
	}

	.health-form-title {
		padding-top: 6rem;
		font-size: 22px;
		color: #4b4b4b;
	}

	input[type=text] {
		margin-bottom: 7rem;
		padding-left: 2rem;
		padding-right:2rem;
	    background-color: #008cd494;
	    background-image: url('../Assets/Img/searchicon.png');
	    background-position: 95% 50%;
	    background-repeat: no-repeat;
	    background-size: 3rem 3rem;
	    color: white;
	    width: 33vw;
	    height: 6rem;
	    border-radius: 0.5rem;
	    border: 2px solid #ffffff60;
	}

	input::placeholder{
		color: white;
		font-size: 2rem;
	}


	/*Legend Styling*/

	.row {
		display: flex;
		padding-left: 15px;

	}

	.column {
		flex: 50%;
	}

	.legend {
		font-size: 12px;
		padding-bottom: 40px;
		text-align: left;
	}

	.legend-description {
		padding: 5px;
		display: flex;
	}

	.legend-description-copy {
		padding: 5px;
	}

	.legend-key {
		padding-top: 5px;
		padding-bottom: 5px;
		padding-left: 20px;
		padding-right: 20px;
		margin-right: 15px;
		font-weight: 700;
		color: #fff;
	}

	/*Main Body Styling*/

	.instruction {
		font-size: 14px;
		text-align: left;
		font-style: italic;
	}

	.practice_group_name {
		text-align: left;
		text-transform: uppercase;
		padding-bottom: 30px;
		padding-top: 30px;
		font-weight: 600;
		font-size: 21px;
		color: #2f2f2f;
	}

	.group-name-header {
		display: flex;
		align-items: center;
	}

	.practicegroup-expand {
		padding-left: 5px;
		font-weight: 600;
		font-size: 18px;
		padding-bottom: 4px;
	}

	.annotations-field {
		padding-top: 30px;
		padding-bottom: 60px;
		text-align: left;
	}

	.annotations-field-copy {
		text-align: left;
		padding-top: 30px;
		font-weight: 600;
		font-size: 16px;
		color: #2f2f2f;
	}

	.annotations-copy {
		text-align: left;
		font-weight: 600;
		font-size: 16px;
		color: #2f2f2f;
	}


}
