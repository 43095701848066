.three-five-three {

	padding-bottom: 200px;

	.three-five-three__view {
		width: fit-content;
		margin-left: auto;
		margin-right: auto;
	}

	.tft-row {

		display: flex;

		.tft-row__team-name {
			align-self: flex-end;
			color: #FFF;
			text-align: right;
			width: 20rem;
			height: 3rem;
			text-overflow: ellipsis;
			overflow: hidden;
			padding-right: 2rem;
			font-weight: bold;
			padding-top: 7px;
			margin-bottom: 10px;
		}


		.tft-row__cells {
			margin-left:3rem;
			margin-right:0.25rem;
			display: flex;
		}

		.tft-row__cells_header {
			color: white;
		    height: 15rem;
		    text-align: left;
		    font-weight: bold;
		    -webkit-mask-image: -webkit-linear-gradient(top, white, #000000c7, #00000000);
		    -webkit-writing-mode: vertical-rl;
		    writing-mode: vertical-rl;
		    -webkit-transform: rotate(180deg);
		    transform: rotate(180deg);
		    white-space: nowrap;
		    padding-top: 2rem;
		    padding-right: 1rem;
		    font-size: 13px;
		}

		.tft-row__cells_practice {
		    color: white;
		    height: 15rem;
		    text-align: left;
		    font-weight: bold;
		    -webkit-writing-mode: vertical-rl;
		    writing-mode: vertical-rl;
		    -webkit-transform: rotate(180deg);
		    transform: rotate(180deg);
		    white-space: nowrap;
		    padding-top: 2rem;
		    padding-right: 1rem;
		    font-size: 13px;

		}

	}

	.three-five-three__date{
		color: #FFF;
	}

	.three-five-three__dateSlider {
		width: 65%;
	    margin: auto;
	    padding-top:20px;
	}

	.three-five-three-with-legend {
		display: flex;
	}

	.three-five-three-legend {
		position: relative;
		width: 18rem;
	}
	
}