.Navbar-main {
	background-color: #fff;
	height: 8rem;
	width: 100vw;
	position: fixed;
	left: 0px;
	top: 0px;
	z-index: 100;
}
.logo-div a:link {
	text-decoration: none;
}
.Navbar-logo {
	position: fixed;
	left: 6.3rem;
	top: 2.2rem;
	.Navbar-logo__content {
		font-size: 3.4rem;
		font-weight: 600;
		line-height: 1;
		letter-spacing: -1.7px;
		color: #1b1b1b;
	}
	a:hover {
	  text-decoration: none;
	}
}
.Navbar-menu__box {
	position: fixed;
	right: 2%;
	top: 0;
	transform: translateX(-50%);
}
.Navbar-menu__list {
	margin: 2.4rem;
}
.Navbar-menu__item {
	display: inline-block;
	padding: 0 1rem;
	font-size: 1.8rem;
}

.update_a_team {
	border: 0.2rem solid #4A90E2;
    border-radius: 3rem;
    width: 120%;
    margin-top: auto;
    margin-bottom: auto;
    padding: 1rem 2rem 1rem 2rem;;
    font-size: 16px;
}

.update_a_team:hover {
	background-color: #4A90E2;
	color: #fff;
	text-decoration: none;
	border: 0.2rem solid #4A90E2;
}

.new_team {
	border: 0.2rem solid #4A90E2;
    border-radius: 3rem;
    width: 120%;
    margin-top: auto;
    margin-bottom: auto;
    padding: 1rem 2rem 1rem 2rem;;
    font-size: 16px;
    background-color: #FFF;
}

.new_team:hover {
	background-color: #4A90E2;
	color: #fff;
	text-decoration: none;
	border: 0.2rem solid #4A90E2;
}

#new-team__page-button {
	margin-left:auto;
	margin-right:auto;
}

.show {
	display: block;
	opacity: 0.95;
	animation-name: topSlideIn;
	animation-duration: 1s;
	top: 8rem;
	width: 48.5%;
	height: 100rem;
	position: absolute;
	right: 0px;
	z-index: 90;

  /* -webkit-transition: transform .7s ease-in-out; */
  -moz-transition: transform .7s ease-in-out;
  -ms-transition: transform .7s ease-in-out;
  -o-transition: transform .7s ease-in-out;
  transition: transform .7s ease-in-out;
}
.hidden {
	display: block;
	animation-name: topSlideOut;
	animation-duration: 3s;
	top: -100rem;
	width: 0px !important;	
	height: 0rem !important;
	position: absolute;
	right: 0px;
	z-index: -100 !important;
	transition: 1s ease-in-out;
}

.button_box {
	display: flex;
	font-size: 3.5rem;
	position: fixed;
	top: 2.8rem;
	right: 10%;
	transform: translateX(-50%);
  cursor: pointer;
  width: auto;
  margin: 0 auto;
}
.bar {
  display: block;
  height: 2px;
  width: 2rem;
  background-color: #000;
  margin: 5px auto;
}

.button {
	display: inline-block;
  -webkit-transition: all .7s ease;
  -moz-transition: all .7s ease;
  -ms-transition: all .7s ease;
  -o-transition: all .7s ease;
  transition: all .7s ease;
}
.button .active .top {
  -webkit-transform: translateY(7px) rotateZ(45deg);
  -moz-transform: translateY(7px) rotateZ(45deg);
  -ms-transform: translateY(7px) rotateZ(45deg);
  -o-transform: translateY(7px) rotateZ(45deg);
  transform: translateY(7px) rotateZ(45deg);
}
.button .active .middle {
  width: 0px;
}
.button .active .bottom {
  -webkit-transform: translateY(-7px) rotateZ(-45deg);
  -moz-transform: translateY(-7px) rotateZ(-45deg);
  -ms-transform: translateY(-7px) rotateZ(-45deg);
  -o-transform: translateY(-7px) rotateZ(-45deg);
  transform: translateY(-7px) rotateZ(-45deg);
}
@keyframes topSlideIn {
	0% {  
		opacity: 0;
		transform: translateY(-100rem); 	
		-ms-transform: translateY(-100rem); 	
	}
	100% {
		opacity: 0.9;
		transform: translateY(0rem); 
		-ms-transform: translateY(0rem); 
	} 
}

@keyframes topSlideOut {
	0% {  
		opacity: 0.9;
		transform: translateY(0rem); 	
		-ms-transform: translateY(0rem);
	}
	100% {
		opacity: 0;
		transform: translateY(-100rem); 
		-ms-transform: translateY(-100rem);
	} 
}

.TestForm {
	position: absolute;
	right: 10px;
	.user, .password, .scores {
		font-size: 1.6rem;
		padding: 1rem;

	}
}

