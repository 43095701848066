.column {
    display: flex;
    flex-direction: row;
    color: white;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;

    .colours-column {
        padding-right: 1.5rem;
    }

    /*Styles the squares with colours*/
    .three-five-three-legend {
        width: 1.5rem;
        height: 1.5rem;
        margin-top: 1.5rem;
    }

    .legend-description {
        text-align: left;
        font-size: 1.2rem;
        vertical-align: middle;
        line-height: 1.5rem;
        padding-top: 1.5rem;
    }

    .effective-practice{
        border-style: solid;
        border-width: 1px;
        border-color: white;
    }

    .spacer {
        height: 3rem;
    }
}