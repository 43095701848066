.info-panel {

	position: fixed;
	z-index: 110;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	transition: opacity 333ms;

	.info-panel_header {
		display: grid;
		grid-template-columns: 1fr 50px;
		padding: 0px 2rem 2rem 2rem;

		.primary {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-start;
		}
		.health {
			display: flex;
			justify-content: space-between;
			align-items: center;
			color: #fff;

			.trend {
				margin-left: 2rem;
			}
			.score {
				border-radius: 40px;
			}
		}
	}


	.update_team {
	border: 0.2rem solid #ffffff82;
    border-radius: 3rem;
    width: 120%;
    margin-top: auto;
    margin-bottom: auto;
    padding: 0.5rem 1rem 0.5rem 1rem;
    font-size: 14px;
    color: #fff;
	}

	.update_team:hover {
		background-color: #4A90E2;
	}

	.info-panel__box {
		display: flex;
		box-shadow: 0 8px 16px rgba(0, 0, 0, 0.333);
		background: #FFFFFF;
		text-align: left;
		border-radius: 1rem;
		// position: absolute;
		// margin: 1.5rem;
		padding-bottom: 1rem;
		padding-top: 1rem;
	}

	// .info-panel__team-name-header {
	// 	color: white;
	// 	text-align: left;
	// 	padding-top: 20px;
	// }

	.info-panel-close {
		text-align: right;
		padding-bottom: 5px;
		font-size: 25px;
		color: #fff;
	}

	.info-panel-actions {
		margin-right: 25px;
	}

	.info-panel-button {
		margin-right: 20px;
	}
	
	.info-panel-button a:hover{
		text-decoration: none;
	}

	.info-panel__team-name{
		color: white;
		text-align: left;
		font-size: 2rem;
		margin-top: 0rem;
		margin-bottom: 0rem;
		opacity: 1;
	}
	.info-panel__practice-group{
		font-size: 4rem;
		margin-top: 0rem;
		margin-bottom: 1rem;
		opacity: 1;
	}

	.info-panel__view-button {
		padding-right: 2.4rem;
	}

	.info-panel__view-button a:hover {
		text-decoration: none;
	}

	.info-panel__trend-arrow__up {
		transform: scale(0.75);
		float: left;
		padding: 4px 4px;
	}

	.info-panel__trend-arrow__down {
		transform: rotate(180deg) scale(0.75);
		float: left;
		padding: 4px 4px;

	}
	.info-panel__trend-arrow__none{
		display: none;
	}

	p {
		float: left;
	}
}

.black-layer {
	background: #111111;
	height: 100vh;
	left:0px;
	top:0px;
	width: 100vw;
	z-index: 100;
	position:fixed;
	opacity:0.8;
	-webkit-mask-image: linear-gradient(white, white, #00000088);
	
}

.black-layer.is-hidden {
	display: none;
}